import React, { useState } from 'react';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import client from '../../../graphql/apollo/apolloClient';
import HidePasswordIcon from '../HidePasswordIcon';
import Svgs from '../../../res/svgs';
import usePasswordSchema from './hooks/usePasswordSchema';

interface ResetPasswordFormProps {
	token: string;
}

interface ResetPasswordVariables {
	password: string;
	passwordConfirmation: string;
	token: string;
}

function resetPassword(variables: ResetPasswordVariables) {
	return client.mutate({
		mutation: gql`
			mutation (
				$password: String!
				$passwordConfirmation: String!
				$token: String!
			) {
				userUpdatePasswordWithToken(
					password: $password
					passwordConfirmation: $passwordConfirmation
					resetPasswordToken: $token
				) {
					__typename
				}
			}
		`,
		variables,
	});
}

function ResetPasswordFrom({ token }: ResetPasswordFormProps) {
	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');
	const [passwordUpdateCompleted, setPasswordUpdateCompleted] = useState(false);
	const [passwordHidden, setPasswordHidden] = useState(true);
	const [confirmPasswordHidden, setConfirmPasswordHidden] = useState(true);
	const [shouldSubmitForm, setShouldSubmitForm] = useState(true);

	const passwordMatching = password === passwordConfirmation;
	const submitable = password && passwordConfirmation && passwordMatching;

	const { validatePassword, errMsg } = usePasswordSchema(
		password,
		passwordConfirmation,
	);

	const onResetPassword = (e: { preventDefault: () => void }) => {
		e.preventDefault();

		if (!shouldSubmitForm) {
			setShouldSubmitForm(true);
			return;
		}

		validatePassword();

		if (!submitable) {
			return;
		}

		resetPassword({ password, passwordConfirmation, token })
			.then((res) => {
				if (res?.data?.userUpdatePasswordWithToken?.__typename) {
					setPasswordUpdateCompleted(true);
				}
			})
			.catch((e) => console.error(e.message));
	};

	const togglePasswordVisibility = (field: string) => {
		if (field === 'password') {
			setPasswordHidden(!passwordHidden);
		} else if (field === 'confirmPassword') {
			setConfirmPasswordHidden(!confirmPasswordHidden);
		}

		setShouldSubmitForm(false);
	};

	return (
		<StyledForm onSubmit={onResetPassword}>
			<Svgs.OverrideBrand />
			{!passwordUpdateCompleted && <p className={'error'}>{errMsg}</p>}
			<h1>
				{passwordUpdateCompleted ? `New password set!` : `Set New Password`}
			</h1>
			{!passwordUpdateCompleted && (
				<div className="input-container">
					<input
						type={passwordHidden ? 'password' : 'text'}
						onChange={(e) => setPassword(e.target.value)}
						placeholder={'Password'}
					/>
					<div className="hide-password-icon">
						<HidePasswordIcon
							passwordHidden={passwordHidden}
							onClick={() => togglePasswordVisibility('password')}
						/>
					</div>
				</div>
			)}
			{!passwordUpdateCompleted && (
				<div className="input-container">
					<input
						type={confirmPasswordHidden ? 'password' : 'text'}
						onChange={(e) => setPasswordConfirmation(e.target.value)}
						placeholder={'Confirm Password'}
					/>
					<div className="hide-password-icon">
						<HidePasswordIcon
							passwordHidden={confirmPasswordHidden}
							onClick={() => togglePasswordVisibility('confirmPassword')}
						/>
					</div>
				</div>
			)}
			{!passwordUpdateCompleted && (
				<div>
					<input type="submit" value="Reset Password" />
				</div>
			)}
		</StyledForm>
	);
}

const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2em;
	min-width: 100%;

	h1 {
		text-align: center;
		font-size: 1.3em;
	}

	.error {
		text-align: center;
		color: #0cbcf6;
	}

	img {
		max-width: 100%;
		margin: 1em auto;
	}

	.input-container {
		position: relative;
		width: 100%;
		max-width: 500px;
		display: flex;
		align-items: center;
		margin-bottom: 1em;
	}

	input[type='password'],
	input[type='text'] {
		border-radius: 5em;
		padding: 1em;
		border: 1px solid #dadada;
		margin: 0.3em;
		width: 100%;
		box-sizing: border-box;
	}

	input[type='submit'] {
		background-color: #0cbcf6;
		border: 1px solid #dadada;
		border-radius: 5em;
		color: white;
		padding: 1em;
		margin-top: 1em;
		cursor: pointer;
		min-width: 500px;
	}

	input[type='submit']:disabled {
		background-color: lightgray;
	}

	.hide-password-icon {
		position: absolute;
		right: 10px;
		transform: translateY(-55%);
		cursor: pointer;
		width: 24px;
		height: 24px;
	}

	@media screen and (max-width: 768px) {
		display: flex;
		padding: 0 2em;
		flex-direction: column;
		justify-content: center;
		box-sizing: border-box;

		h1 {
			text-align: center;
			font-size: 1em;
		}

		input[type='password'],
		input[type='text'],
		input[type='submit'] {
			font-size: 16px;
			width: 100%;
			padding: 1em;
		}

		.hide-password-icon {
			position: absolute;
			width: 10px;
			margin-bottom: 2.5%;
		}
	}

	@media screen and (max-width: 1200px) {
		display: flex;
		padding: 0 5em;
		flex-direction: column;
		justify-content: center;

		img {
			width: 40vw;
			max-width: none;
		}

		h1 {
			font-size: 3em;
		}

		input {
			font-size: 2em;
		}

		.hide-password-icon {
			position: absolute;
			bottom: 68%;
			cursor: pointer;
		}
	}
`;

export default ResetPasswordFrom;
